<template>
  <el-select
    :value="localValue"
    :size="size"
    :multiple="multiple"
    :disabled="disabled"
    clearable
    :placeholder="placeholder"
    @change="selected"
  >
    <el-option
      v-for="(negotiationConfig, negotiationType) in $companyConfiguration.cardStageNegotiations.getNegotiationTypesConfig()"
      :key="negotiationType"
      :label="negotiationConfig.showName"
      :value="negotiationType"
    >
    </el-option>
  </el-select>

</template>

<script>

export default {
  name: "cards-stage-negotiation-select",
  components: {},
  props: {
    value: {},
    size: {type: String, default: '' },
    disabled: {type: Boolean, default: false },
    multiple: {type: Boolean, default: false },
    placeholder: {type: String, default: 'Ответственный' },
  },

  data() {
    return {
      localValue: this.value,
    }
  },
  beforeMount() {},
  watch: {},
  computed: {},
  methods: {
    selected(value) {
      this.localValue = value;
      this.$emit('input', value);
      this.$emit('change', value);
    }
  },

}
</script>

<style scoped>

</style>