<template>
  <div style="display: inline-block">
    <el-input
      v-model="localValue"
      :size="size"
      :disabled="disabled"
      clearable
      :placeholder="placeholder"
      prefix-icon="el-icon-search"
      :show-word-limit="showWordLimit"
      :maxlength="maxLength"
      @clear="changed"
      v-debounce:500="changed"
    >
  </el-input>
  </div>

</template>

<script>

export default {
  name: "search-input",
  components: {},
  props: {
    value: {},
    size: {type: String, default: '' },
    disabled: {type: Boolean, default: false },
    placeholder: {type: String, default: 'Поиск' },
    showWordLimit: {type: Boolean, default: false },
    maxLength: {type: Number, default: 100 },
  },

  data() {
    return {
      localValue: this.value,
    }
  },
  mounted() {
  },
  watch: {},
  computed: {},
  methods: {
    changed(value) {
      this.$emit('input', this.localValue);
      this.$emit('change', this.localValue);
    }
  },

}
</script>

<style>

</style>